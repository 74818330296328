import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "fieldset", "field", "mobileScreenshot", "desktopScreenshot", "schemeToggle", "exampleField" ]
  static classes = [ "darkMode", "dashboardExample" ]
  
  connect() {
    this.enableFilters()
  }
  
  enableFilters() {
    if (!this.hasFieldsetTarget) { return }
    this.loadPermutationCode()
    this.fieldsetTarget.disabled = false
  }
  
  updateScreenshots(event) {
    if (!this.hasMobileScreenshotTarget) { return }
    if (!this.hasDesktopScreenshotTarget) { return }
    
    this.updateScreenshotSrc(this.mobileScreenshotTargets)
    this.updateScreenshotSrc(this.desktopScreenshotTargets)
    
    if (event !== undefined) {
      this.updateSchemeForInterationWithField(event.currentTarget)
    }
    this.savePermutationCode()
  }
  
  toggleScheme() {
    if (!this.hasSchemeToggleTarget) { return }
    this.element.classList.toggle(this.darkModeClass, this.schemeToggleTarget.checked)
  }
  
  updateSchemeForInterationWithField(field) {
    const fieldName = field?.name
    if (!fieldName) { return }
    
    switch (fieldName) {
      case "light_base":
      case "light_accent":
        this.showLightScreenshots(); break;
      case "dark_base":
      case "dark_accent":
        this.showDarkScreenshots(); break;
    }
  }
  
  showLightScreenshots() {
    if (!this.hasSchemeToggleTarget) { return }
    this.schemeToggleTarget.checked = false
    this.toggleScheme()
  }
  
  showDarkScreenshots() {
    if (!this.hasSchemeToggleTarget) { return }
    this.schemeToggleTarget.checked = true
    this.toggleScheme()
  }
  
  toggleExample() {
    if (!this.hasExampleFieldTarget) { return }
    this.element.classList.toggle(this.dashboardExampleClass, this.exampleFieldTarget.value == "dashboard")
  }
  
  updateScreenshotSrc(targets) {
    targets.forEach(target => {
      const scheme = target.dataset.scheme
      const newSrc = target.dataset.srcPattern.replace("%permutation_code%", this.permutationCodeForScheme(scheme))
      target.src = newSrc
    })
  }
  
  fieldValueFor(fieldName) {
    const field = this.fieldTargets.find(fieldTarget => fieldTarget.name === fieldName)
    if (field === undefined) { return undefined }
    return field.value
  }
  
  fieldPermutationCodeValueFor(fieldName, defaultValue) {
    const field = this.fieldTargets.find(fieldTarget => fieldTarget.name === fieldName)
    if (field === undefined) return defaultValue
    if (field.dataset.type === "boolean") {
      return field.value == "true" ? "T" : "F"
    } else {
      return field.value
    }
  }
  
  setFieldValueForPermutationCode(fieldName, permutationCode) {
    const field = this.fieldTargets.find(fieldTarget => fieldTarget.name === fieldName)
    if (field === undefined) return false
    if (field.dataset.type === "boolean") {
      field.value = permutationCode === "T" ? "true" : "false"
    } else {
      field.value = permutationCode
    }
  }
  
  permutationCodeForScheme(scheme) {
    return [
      this.permutationCodeStart,
      "-",
      this.permutationCodePartForScheme(scheme)
    ].join("")
  }
  
  permutationCodePartForScheme(scheme) {
    return [
      scheme == "light" ? "L" : "D",
      this.fieldPermutationCodeValueFor(scheme == "light" ? "light_base" : "dark_base", 0),
      this.fieldPermutationCodeValueFor(scheme == "light" ? "light_accent" : "dark_accent", 0)
    ].join("")
  }
  
  get permutationCodeStart() {
    return [
      "T01",
      this.fieldPermutationCodeValueFor("fonts_wide?", "T"),
      this.fieldPermutationCodeValueFor("borders_broad?", "T"),
      this.fieldPermutationCodeValueFor("menu_style", 0)
    ].join("")
  }
  
  get fullPermutationCode() {
    return [
      this.permutationCodeStart,
      "-",
      this.permutationCodePartForScheme("light"),
      "-",
      this.permutationCodePartForScheme("dark")
    ].join("")
  }
  
  set fullPermutationCode(code) {
    if (code === null) { return }
    const codeParts = [
      "T01",     // version
      "(T|F)",   // (fonts_wide?)
      "(T|F)",   // (borders_broad?)
      "([0-9])", // (menu_style)
      "-",
      "L",       // light scheme
      "([0-9])", // (light_base)
      "([0-9])", // (light_accent)
      "-",
      "D",       // dark scheme
      "([0-9])", // (dark_base)
      "([0-9])"  // (dark_accent)
    ].join("")
    const codePattern = new RegExp(codeParts)
    
    const matches = code.match(codePattern)
    if (matches === null) { return }
    
    const codesForFields = matches.slice(1) // first includes whole first match
    
    "fonts_wide? borders_broad? menu_style light_base light_accent dark_base dark_accent".split(" ").forEach((fieldName, index) => {
      this.setFieldValueForPermutationCode(fieldName, codesForFields[index])
    })
    
    this.updateScreenshots()
  }
  
  savePermutationCode() {
    this.updateURLWithPermutationCode()
    sessionStorage.setItem("trim", this.fullPermutationCode)
  }
  
  loadPermutationCode() {
    let url = new URL(window.location.href)
    let trim = url.searchParams.get('trim')
    const useSessionStorage = (trim === null)
    
    if (useSessionStorage) {
      trim = sessionStorage.getItem("trim")
    }
    
    if (trim === undefined) { return }
    
    this.fullPermutationCode = trim
    
    if (useSessionStorage) {
      this.updateURLWithPermutationCode()
    }
  }
  
  updateURLWithPermutationCode() {
    const trim = this.fullPermutationCode
    let url = new URL(window.location.href)
    url.searchParams.set('trim', trim)
    
    history.replaceState({}, '', url)
  }
}
